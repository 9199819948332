import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import { Container, Image, Row } from 'react-bootstrap';
import SplitType from 'split-type';
import { fadeInUp, zoomIn } from '../animation/FadeInUp';

const HowAreWeSec = () => {
    const oddsTextRef = useRef(null);
    const oddsText2Ref = useRef(null);
    const oddsText3Ref = useRef(null);
    const oddsText4Ref = useRef(null);
    const oddsText5Ref = useRef(null);
    const oddsText6Ref = useRef(null);
    const oddsText7Ref = useRef(null);
    const oddsText8Ref = useRef(null);
    const [isVisible, setIsVisible] = useState(false); // State to track visibility

    useEffect(() => {
        const split = new SplitType('.odds-content-1,.odds-content-3', { types: 'chars,words' });

        const defaults = gsap.timeline();
        defaults.set(".odds-content-1 .char,.odds-content-3 .char", { opacity: 0, y: 20, skewY: 10, duration: 0 });

        const applyTextAnimation = (textRef) => {
            const chars = textRef.querySelectorAll('.char');

            const tl = gsap.timeline({ delay: 1 });

            tl.to(
                chars,
                {
                    opacity: 1,
                    y: 0,
                    skewY: 0,
                    stagger: { amount: 0.4 },
                    duration: 1,
                }
            );
        };

        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsVisible(true); // Set visibility state to true
                    const targetRef = entry.target;
                    if (targetRef === oddsTextRef.current) {
                        fadeInUp(".title-ref", { duration: 0.5, delay: 0.5 });
                        applyTextAnimation(targetRef);
                        zoomIn(".image-fidd", { duration: 0.5, delay: 1.5 });
                        fadeInUp(".subtitle-ref", { duration: 0.5, delay: 1.7 });
                    } else if (targetRef === oddsText2Ref.current) {
                        applyTextAnimation(targetRef);
                    } else if (targetRef === oddsText3Ref.current) {
                        applyTextAnimation(targetRef);
                    } else if (targetRef === oddsText4Ref.current) {
                        applyTextAnimation(targetRef);
                    } else if (targetRef === oddsText5Ref.current) {
                        applyTextAnimation(targetRef);
                    } else if (targetRef === oddsText6Ref.current) {
                        applyTextAnimation(targetRef);
                    } else if (targetRef === oddsText7Ref.current) {
                        applyTextAnimation(targetRef);
                    } else if (targetRef === oddsText8Ref.current) {
                        applyTextAnimation(targetRef);
                    }
                    observer.unobserve(targetRef); 
                } else {
                    setIsVisible(false); // Set visibility state to false when out of view
                }
            });
        }, observerOptions);

        if (oddsTextRef.current) {
            observer.observe(oddsTextRef.current);
        }
        if (oddsText2Ref.current) {
            observer.observe(oddsText2Ref.current);
        }
        if (oddsText3Ref.current) {
            observer.observe(oddsText3Ref.current);
        }
        if (oddsText4Ref.current) {
            observer.observe(oddsText4Ref.current);
        }
        if (oddsText5Ref.current) {
            observer.observe(oddsText5Ref.current);
        }
        if (oddsText6Ref.current) {
            observer.observe(oddsText6Ref.current);
        }
        if (oddsText7Ref.current) {
            observer.observe(oddsText7Ref.current);
        }
        if (oddsText8Ref.current) {
            observer.observe(oddsText8Ref.current);
        }

        return () => {
            if (oddsTextRef.current) {
                observer.unobserve(oddsTextRef.current);
            }
            if (oddsText2Ref.current) {
                observer.unobserve(oddsText2Ref.current);
            }
            if (oddsText3Ref.current) {
                observer.unobserve(oddsText3Ref.current);
            }
            if (oddsText4Ref.current) {
                observer.unobserve(oddsText4Ref.current);
            }
            if (oddsText5Ref.current) {
                observer.unobserve(oddsText5Ref.current);
            }
            if (oddsText6Ref.current) {
                observer.unobserve(oddsText6Ref.current);
            }
            if (oddsText7Ref.current) {
                observer.unobserve(oddsText7Ref.current);
                fadeInUp(".old-anime-1", { duration: 0.5 });
                zoomIn(".imagezoom1", { duration: 1.5 });

            }
            if (oddsText8Ref.current) {
                observer.unobserve(oddsText8Ref.current);
                fadeInUp(".old-anime-2", { duration: 0.5 });
                zoomIn(".imagezoom2", { duration: 1.5 });
            }
            
        };
    }, []);

    // useEffect(() => {
    //     if (!isVisible) {
    //         // Reset the animation when section is out of view
    //         gsap.set(".odds-content-1 .char,.odds-content-3 .char", { opacity: 0, y: 20, skewY: 10, duration: 0 });
    //         gsap.set(".title-ref,.image-fidd,.subtitle-ref", { opacity: 0});
    //     }
    // }, [isVisible]);

    return (
        <>
            <Image src={'/img/defi-shadow-shape.png'} className='def-shadow' width={'300'} height={'300'} />
            <div className='howwe-area'>
                <Container>
                    <Row>
                        <div className='col-md-12 col-lg-6'>
                            <div className='section-title-area'>
                                <h2 className='title-ref gradientText mb-4' style={{opacity: 0}}>How are we different?</h2>
                                <p className='odds-content-1 text-md-light' ref={oddsTextRef}>
                                    GAMBET offers a revolutionary gameplay in raffles where up to 90% of ticket proceeds are distributed as prizes to at least 10% of participants.
                                </p>
                                <p className='odds-content-3 text-md-light mb-0' ref={oddsText3Ref}>
                                    Traditional raffles lack transparency, favoring just a few winners from millions of tickets sold. 
                                </p>
                                <p className='odds-content-1 text-md-light' ref={oddsText4Ref}>
                                    Our raffles will have fixed number of tickets which makes the odds clear, giving control to the participants on their probability of win
                                </p>
                                <p className='odds-content-1 text-md-light' ref={oddsText5Ref}>
                                    We are a community first platform designed on the principals of decentralized ownership and exclusive incentivization for community participation.
                                </p>
                                <p className='odds-content-1 text-md-light' ref={oddsText6Ref}>
                                    $GAMBET is the native utility token powering our ecosystem. Staking $GAMBET earns the users exclusive privileges to premium raffle competitions.
                                </p>
                            </div>
                        </div>
                        <div className='col-md-12 col-lg-6 text-start text-md-center'>
                            <Image className='image-fidd' src={'/img/howarewe.png'} style={{opacity: 0}} alt="How are we different?" />
                        </div>
                        <div className='col-12'>
                        <h4 className='diff-grad-color subtitle-ref text-center mt-4 mb-4 mb-md-3' style={{opacity: 0}}>GAMBET offers a revolutionary gameplay where, up to 90% of ticket proceeds are distributed as prizes</h4>
                        </div>
                    </Row>
                </Container>
            </div>
            <div className='grid-odds container'>
                <Row>
                    <div className='col-lg-6'>
                        <div className='odds-card'>
                            <div className='olds-content'>
                                <h4 className='old-anime-1' style={{ opacity: 0 }}>Clear ODDS</h4>
                                <div className='text-md-light odds-content-1 mb-0' ref={oddsText7Ref}>Majority of raffles will have a fixed number of tickets, which makes the odds very clear.</div>
                            </div>
                            <img src='/img/odd-image-1.png' className='imagezoom1' style={{ opacity: 0 }} alt='Fixed odds' />
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='odds-card'>
                            <div className='olds-content'>
                                <h4 className='old-anime-2' style={{ opacity: 0 }}>maximum winners</h4>
                                <div className='text-md-light odds-content-2 mb-0' ref={oddsText8Ref}>Majority of raffles will have prizes distributed to at least 10% of the participants.</div>
                            </div>
                            <img src='/img/odd-image-2.png' className='imagezoom2' style={{ opacity: 0 }} alt='maximum winners' />
                        </div>
                    </div>
                </Row>
            </div>
        </>
    );
};

export default HowAreWeSec;
