import React, { useEffect, useState } from 'react';
import fullpage from 'fullpage.js';
import 'fullpage.js/dist/fullpage.css';
import Footer from '../layout/Footer';
import Banner from '../components/Banner';
import GambetSec from '../components/GambetSec';
import RafflesSec from '../components/RafflesSec';
import RoadmapSec from '../components/Roadmap';
import HowAreWeSec from '../components/HowAreWeSec';
import FaqSec from '../components/FaqSec';
import Deflationary from '../components/Deflationary';
import NewsSec from '../components/NewsSec';

const HomePage = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        new fullpage('#homepageFull', {
            sectionsColor: ['#040517', '#040517', '#040517', '#040517', '#040517', '#040517', '#040517', '#040517', '#040517'],
            anchors: ['gambet', 'prizes', 'different', 'raffles', 'community', 'roadmap', 'news', 'faqs', 'footer'],
            responsiveWidth: 768,
            scrollOverflow: true,
            afterLoad: function (origin, destination, direction) {
                if (destination.index === 1) {
                    setIsVisible(true);
                }
            },
        });
    }, []);

    useEffect(() => {
        const removeWatermarkDiv = () => {
            const watermarkParent = document.querySelector('.fp-watermark');
            if (watermarkParent) {
                watermarkParent.remove();
            }
        };

        window.addEventListener('load', removeWatermarkDiv);
    }, []);

    return (
        <div id="homepageFull">
            <div className="section">
                <Banner />
            </div>
            <div className="section">
                <Deflationary />
            </div>
            <div className="section">
                <GambetSec
                    isVisible={isVisible}
                    setIsVisible={setIsVisible}
                />
            </div>
            <div className='section'>
                <HowAreWeSec />
            </div>
            <div className="section">
                <RafflesSec />
            </div>
            {/* <div className="section">
                <Communitysec />
            </div> */}
            
            <div className="section">
                <RoadmapSec />
            </div>
            <div className="section">
                <NewsSec />
            </div>
            <div className="section">
                <FaqSec />
            </div>
            <div className="section">
               <Footer />
            </div>
        </div>
    );
};

export default HomePage;
