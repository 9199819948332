import React, { useEffect, useRef } from 'react';
import SplitType from 'split-type';
import { gsap } from 'gsap';

const TextSplitAnimation = ({ text }) => {
    const textRef = useRef(null);

    useEffect(() => {
        const split = new SplitType(textRef.current, { types: 'chars,words' });
        const charss = textRef.current.querySelectorAll('.banner-desc div .char');

        const tl = gsap.timeline({ delay: 1.5 });
        
        tl.fromTo(
            charss,
            { opacity: 0, y: 20,skewY: 10 },
            {
                opacity: 1,
                y: 0,
                ease: 'power4.out',
                skewY: 0,
                stagger: { amount: 0.4 },
                duration: 1,
            }
        );

        return () => {
            // Clean up animation on component unmount
            tl.kill();
        };
    }, [text]);

    return <div ref={textRef}>{text}</div>;
};

export default TextSplitAnimation;
