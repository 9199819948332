import React, { useEffect } from 'react';
import gsap from 'gsap';
import TextSplitAnimation from './WordWiseAnimation';
import { fadeInUp } from '../animation/FadeInUp';
import { Link } from 'react-router-dom';

const Banner = () => {
    const textLine = "Web3 powered next generation raffle gaming platform";

    useEffect(() => {
        fadeInUp(".banner-gambet-title", { duration: 0.5 , delay: 0.7});
        fadeInUp(".btn-gradient-bg,.bordered-btn-bg", { duration: 0.5, delay: 1.7 });
    }, []);

    return (
        <section className='banner-area'>
            <img src='/img/banner-img.jpg' className='banner-img w-100 img-fluid' alt='background' />
            <div className='container'>
                <div className='col-md-8 col-lg-6'>
                    <img src='/img/gambet-title.png' className='banner-gambet-title' alt='gambet-text' />
                    <div className='banner-desc my-4'>
                        <TextSplitAnimation text={textLine} />
                    </div>
                    <div className='banner-btn-group'>
                        <Link target="_blank" to="https://game.gambet.io/" className='btn-gradient-bg me-2' >
                            <img src='/img/button-gr-bg.png' className='img-fluid' alt='btn-bg' />Play now
                        </Link>
                        <Link className='bordered-btn-bg'>
                            <img src='/img/button-tr-bg.png' className='img-fluid' alt='btn-bg' />WhitePaper
                        </Link>
                    </div>
                    <img src='/img/banner-person.png' className='banner-person' alt='gambet-text' />
                </div>
            </div>
            <img src='/img/banner-bg-transparent.png' className='banner-tr-bg w-100 img-fluid' alt='background' />
        </section>
    );
};

export default Banner;
