import React, { Fragment, useEffect, useRef } from 'react';
import gsap from 'gsap';
import { Container, Image, Row } from 'react-bootstrap';
import SplitType from 'split-type';
import { fadeInUp, zoomIn } from '../animation/FadeInUp';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { Autoplay } from 'swiper/modules';


const Deflationary = () => {
    const oddsText8Ref = useRef(null);
    const oddsText9Ref = useRef(null);
    const oddsText1Ref = useRef(null);
    const oddsText5Ref = useRef(null);
    useEffect(() => {
        const split = new SplitType('.odds-content-1,.odds-content-3,.odds-content-2,.odds-content-4', { types: 'chars,words' });

        const defaults = gsap.timeline();
        defaults.set(".odds-content-1 .char,.odds-content-3 .char,.odds-content-2 .char,.odds-content-4 .char", { opacity: 0, y: 20, skewY: 10, duration: 0 });

        const applyTextAnimation = (textRef) => {
            const chars = textRef.querySelectorAll('.char');

            const tl = gsap.timeline({ delay: 1 });

            tl.to(
                chars,
                {
                    opacity: 1,
                    y: 0,
                    skewY: 0,
                    stagger: { amount: 0.4 },
                    duration: 1,
                }
            );
        };

        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const targetRef = entry.target;
                    if (targetRef === oddsText8Ref.current) {
                        applyTextAnimation(targetRef);
                        fadeInUp(".old-anime-1", { duration: 0.5 });
                        zoomIn(".imagezoom1", { duration: 1.5 });
                    } else if (targetRef === oddsText9Ref.current) {
                        applyTextAnimation(targetRef);
                        fadeInUp(".old-anime-2", { duration: 0.5 });
                        zoomIn(".imagezoom2", { duration: 1.5 });
                    } else if (targetRef === oddsText1Ref.current) {
                        applyTextAnimation(targetRef);
                        fadeInUp(".old-anime-3", { duration: 0.5 });
                        zoomIn(".imagezoom3", { duration: 1.5 });
                    } else if (targetRef === oddsText5Ref.current) {
                        applyTextAnimation(targetRef);
                        fadeInUp(".old-anime-4", { duration: 0.5 });
                        zoomIn(".imagezoom4", { duration: 1.5 });
                    }
                    observer.unobserve(targetRef); 
                } else {
                }
            });
        }, observerOptions);

        if (oddsText8Ref.current) {
            observer.observe(oddsText8Ref.current);
        }
        if (oddsText9Ref.current) {
            observer.observe(oddsText9Ref.current);
        }
        if (oddsText1Ref.current) {
            observer.observe(oddsText1Ref.current);
        }
        if (oddsText5Ref.current) {
            observer.observe(oddsText5Ref.current);
        }

        return () => {
            if (oddsText8Ref.current) {
                observer.unobserve(oddsText8Ref.current);
            }
            if (oddsText9Ref.current) {
                observer.unobserve(oddsText9Ref.current);
            }

            if (oddsText1Ref.current) {
                observer.unobserve(oddsText1Ref.current);
            }
            if (oddsText5Ref.current) {
                observer.unobserve(oddsText5Ref.current);
            }
        };
    }, []);
    return (
        <>
            <div className='whatgambet-area'>
                <div className='container'>
                    <Row className='justify-content-center'>
                        <div className='col-md-7 col-12'>
                            <div className='section-title-area mb-5 text-center position-relative'>
                                <h2 className='gradientText title-anime'>What is GAMBET</h2>
                            </div>
                        </div>
                    </Row>
                    <Row className='align-items-center'>
                        <div className='col-lg-5'>
                            <div className='odds-card small-reverse-card right'>
                                <div className='olds-content'>
                                    <h4 className='old-anime-1' style={{ opacity: 0 }}>Raffle Platform</h4>
                                    <div className='text-md-light odds-content-1 mb-0' ref={oddsText8Ref}>We run raffles where you can win various prizes like NFTs, Stable coins, Bitcoin, Altcoins, RWA tokens</div>
                                </div>
                                <img src='/img/what/1.png' className='imagezoom1 filter-shadow' style={{ opacity: 0 }} alt='Fixed odds' />
                            </div>
                            <div className='odds-card small-reverse-card right'>
                                <div className='olds-content'>
                                    <h4 className='old-anime-2' style={{ opacity: 0 }}>Token Launch Partner</h4>
                                    <div className='text-md-light odds-content-2 mb-0' ref={oddsText9Ref}>Win exciting opportunity to get new project tokens upon launch through raffles that we run</div>
                                </div>
                                <img src='/img/what/3.png' className='imagezoom1' style={{ opacity: 0 }} alt='Fixed odds' />
                            </div>
                        </div>
                        <div className='col-lg-2 position-relative text-center'>
                            <Image src='/img/whatis.png' className='centerd-what-image' alt='what is gambet' />
                        </div>
                        <div className='col-lg-5'>
                            <div className='odds-card small-reverse-card'>
                                <div className='olds-content'>
                                    <h4 className='old-anime-3' style={{ opacity: 0 }}>Marketing Partner</h4>
                                    <div className='text-md-light odds-content-3 mb-0' ref={oddsText1Ref}>Web3 companies can use our platform to run raffles of their project tokens to onboard new users and run marketing campaigns.</div>
                                </div>
                                <img src='/img/what/2.png' className='imagezoom3' style={{ opacity: 0 }} alt='Fixed odds' />
                            </div>
                            <div className='odds-card small-reverse-card'>
                                <div className='olds-content'>
                                    <h4 className='old-anime-4' style={{ opacity: 0 }}>Casino</h4>
                                    <div className='text-md-light odds-content-4 mb-0' ref={oddsText5Ref}>You can play exciting games of chance, where you have instant wins</div>
                                </div>
                                <img src='/img/what/4.png' className='imagezoom4' style={{ opacity: 0 }} alt='Fixed odds' />
                            </div>
                        </div>
                    </Row>
                </div>
            </div>
        </>
    );
};


export default Deflationary;
