import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <>

            <div className='footer-sec-area'>
                <div className='container text-center'>
                    <img src='/img/footer-gambet-text.png' className='footer-image' alt='Update Text' />
                    <ul className='social-share-buttons'>
                        <li>
                            <div class="toggle-button">
                                <div class="cubeshape cube">
                                    <div class="first_cube">
                                        <h1><Link to="https://twitter.com/gambet_io" target="_blank">twitter <img src='/img/arrow-up-right.png' alt='Update' /></Link></h1>
                                    </div>
                                    <div class="second_cube">
                                        <h1><Link to="https://twitter.com/gambet_io" target="_blank">twitter <img src='/img/arrow-up-right.png' alt='Update' /></Link></h1>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="toggle-button">
                                <div class="cubeshape cube">
                                    <div class="first_cube">
                                        <h1><Link to="https://t.me/gambet_io" target="_blank">telegram <img src='/img/arrow-up-right.png' alt='Update' /></Link></h1>
                                    </div>
                                    <div class="second_cube">
                                        <h1><Link to="https://t.me/gambet_io" target="_blank">telegram <img src='/img/arrow-up-right.png' alt='Update' /></Link></h1>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="toggle-button">
                                <div class="cubeshape cube">
                                    <div class="first_cube">
                                        <h1><Link to="https://medium.com/@gambet_io" target="_blank">medium <img src='/img/arrow-up-right.png' alt='Update' /></Link></h1>
                                    </div>
                                    <div class="second_cube">
                                        <h1><Link to="https://medium.com/@gambet_io" target="_blank">medium <img src='/img/arrow-up-right.png' alt='Update' /></Link></h1>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="toggle-button">
                                <div class="cubeshape cube">
                                    <div class="first_cube">
                                        <h1><Link to="#">Discord <img src='/img/arrow-up-right.png' alt='Update' /></Link></h1>
                                    </div>
                                    <div class="second_cube">
                                        <h1><Link to="#">Discord <img src='/img/arrow-up-right.png' alt='Update' /></Link></h1>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="toggle-button">
                                <div class="cubeshape cube">
                                    <div class="first_cube">
                                        <h1><Link to="#">$GAMBET <img src='/img/arrow-up-right.png' alt='Update' /></Link></h1>
                                    </div>
                                    <div class="second_cube">
                                        <h1><Link to="#">$GAMBET <img src='/img/arrow-up-right.png' alt='Update' /></Link></h1>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="scrollable-content">
                    <footer class="footer text-center text-md-start">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-6 col-lg-4">
                                    <img className="footer-logo" src="/img/footer-logo.png" loading="lazy" alt="menu" />
                                    <p className="mb-4 mb-md-0 text-md-light">Raffles and games on gambet.io are licensed under license number 546/SUV issued by the Costa Rica Gaming Control Board, authorized and regulated by the Government of Costa Rica.</p>
                                </div>
                                <div class="col-6 col-md-3 offset-lg-2 mb-3 mt-4">
                                    <ul className="footer-links-list">
                                        <li><a href="/about-us#tokenomics">Tokenomics</a></li>
                                        <li><a href="/#roadmap">Roadmap</a></li>
                                        <li><a href="/#faqs">FAQ</a></li>
                                    </ul>
                                </div>
                                <div class="col-6 col-md-3 mb-3 mt-4">
                                    <ul className="footer-links-list">
                                        <li><a href="/#whitepaper">Whitepaper</a></li>
                                        <li><a href="/#news">News</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="bottom-footer">
                            <Container>
                                <div class="row">
                                    <div class="col-12">
                                        <div className="d-flex justify-content-between flex-wrap copyright-area">
                                            <p class="text-center mb-0">Copyright © 2024 GAMBET | All Rights Reserved </p>
                                            <p class="text-center mb-0">Disclaimer: Please ensure you are above 18 years of age and have no restriction in your country to participate in crypto games. </p>
                                        </div>
                                    </div>
                                </div>
                            </Container>
                        </div>
                    </footer>
                </div>
            </div>
        </>
    );
};

export default Footer;