import React, { Fragment, useEffect, useRef } from 'react';
import gsap from 'gsap';
import { Container, Image, Row } from 'react-bootstrap';
import TextSplitAnimation from './WordWiseAnimation';
import Accordion from 'react-bootstrap/Accordion';
import SplitType from 'split-type';
import { fadeInUp, zoomIn } from '../animation/FadeInUp';
import { FreeMode, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';


const Tablesec = () => {

    return (
        <>

            <div className='table-area position-relative'>
                <Image src={'/img/defi-shadow-shape.png'} className='deftable-shape' width={'300'} height={'300'} />
                <Container>
                    <Row className='justify-content-center'>
                        <div className='col-10 '>
                            <div className='section-title-area  text-center position-relative'>
                                <h2 className='gradientText title-anime'>Tiers & Benefits</h2>
                                <h3 className='shadow-text'>BENEFITS</h3>
                            </div>
                        </div>
                        <div className='col-10 mt-3 mt-md-4 mt-lg-3'>
                            <p className=' text-md-light mt-md-4 text-center'>GAMBET values its community of players and is committed to enhancing their gaming experience.
To achieve this, we've introduced a tier system that provides players with additional benefits,
rewards, and opportunities as they progress through various tiers. <br/> <br/>Here's a closer look at the
GAMBET Tier Levels:
</p>
                        </div>
                        <div className='col-12'>
                            <div className='position-relative table-wrapper mt-4' >
                                <table className='striped text-cente'>
                                    <thead>
                                        <tr>
                                            <th><Image src='/img/gambet-title.png' width="100px" /></th>
                                            <th>1 month</th>
                                            <th>3 month</th>
                                            <th>6 month</th>
                                            <th>12 month</th>
                                            <th>24 month</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>10,000</th>
                                            <td>
                                                <div className='table-td d-block'>
                                                    <div className='table-image d-flex flex-column flex-md-row justify-content-center align-items-center'>
                                                        <Image src='/img/bronze.png' className='me-md-2 mb-1 mb-md-0' />
                                                        <div className='table-dec d-flex justify-content-center'>Bronze</div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='table-td d-block'>
                                                    <div className='table-image d-flex flex-column flex-md-row justify-content-center align-items-center'>
                                                        <Image src='/img/silver.png' className='me-md-2 mb-1 mb-md-0' />
                                                        <div className='table-dec d-flex justify-content-center'>Silver</div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='table-td d-block '>
                                                    <div className='table-image d-flex flex-column flex-md-row justify-content-center align-items-center'>
                                                        <Image src='/img/gold.png' className='me-md-2 mb-1 mb-md-0' />
                                                        <div className='table-dec'>Gold</div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='table-td d-block '>
                                                    <div className='table-image d-flex flex-column flex-md-row justify-content-center align-items-center'>
                                                        <Image src='/img/diamond.png' className='me-md-2 mb-1 mb-md-0' />
                                                        <div className='table-dec'>Diamond</div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='table-td d-block '>
                                                    <div className='table-image d-flex flex-column flex-md-row justify-content-center align-items-center'>
                                                        <Image src='/img/platinum.png' className='me-md-2 mb-1 mb-md-0' />
                                                        <div className='table-dec'>Platinum</div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>30,000</th>
                                            <td>
                                                <div className='table-td d-block '>
                                                    <div className='table-image d-flex flex-column flex-md-row justify-content-center align-items-center'>
                                                        <Image src='/img/silver.png' className='me-md-2 mb-1 mb-md-0' />
                                                        <div className='table-dec'>Silver</div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='table-td d-block '>
                                                    <div className='table-image d-flex flex-column flex-md-row justify-content-center align-items-center'>
                                                        <Image src='/img/gold.png' className='me-md-2 mb-1 mb-md-0' />
                                                        <div className='table-dec'>Gold</div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='table-td d-block '>
                                                    <div className='table-image d-flex flex-column flex-md-row justify-content-center align-items-center'>
                                                        <Image src='/img/diamond.png' className='me-md-2 mb-1 mb-md-0' />
                                                        <div className='table-dec'>Diamond</div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='table-td d-block '>
                                                    <div className='table-image d-flex flex-column flex-md-row justify-content-center align-items-center'>
                                                        <Image src='/img/platinum.png' className='me-md-2 mb-1 mb-md-0' />
                                                        <div className='table-dec'>Platinum</div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th>60,000</th>
                                            <td>
                                                <div className='table-td d-block '>
                                                    <div className='table-image d-flex flex-column flex-md-row justify-content-center align-items-center'>
                                                        <Image src='/img/gold.png' className='me-md-2 mb-1 mb-md-0' />
                                                        <div className='table-dec'>Gold</div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='table-td d-block '>
                                                    <div className='table-image d-flex flex-column flex-md-row justify-content-center align-items-center'>
                                                        <Image src='/img/diamond.png' className='me-md-2 mb-1 mb-md-0' />
                                                        <div className='table-dec'>Diamond</div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='table-td d-block '>
                                                    <div className='table-image d-flex flex-column flex-md-row justify-content-center align-items-center'>
                                                        <Image src='/img/platinum.png' className='me-md-2 mb-1 mb-md-0' />
                                                        <div className='table-dec'>Platinum</div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th>120,000</th>
                                            <td>
                                                <div className='table-td d-block '>
                                                    <div className='table-image d-flex flex-column flex-md-row justify-content-center align-items-center'>
                                                        <Image src='/img/diamond.png' className='me-md-2 mb-1 mb-md-0' />
                                                        <div className='table-dec'>Diamond</div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='table-td d-block '>
                                                    <div className='table-image d-flex flex-column flex-md-row justify-content-center align-items-center'>
                                                        <Image src='/img/platinum.png' className='me-md-2 mb-1 mb-md-0' />
                                                        <div className='table-dec'>Platinum</div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th>240,000</th>
                                            <td>
                                                <div className='table-td d-block '>
                                                    <div className='table-image d-flex flex-column flex-md-row justify-content-center align-items-center'>
                                                        <Image src='/img/platinum.png' className='me-md-2 mb-1 mb-md-0' />
                                                        <div className='table-dec'>Platinum</div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div style={{ backgroundImage: 'url(/img/tablebg.png)' }} className='shape-imagetable' />
                            </div>
                        </div>
                        <div className='col-11'>
                            <p className='mt-5 mb-0 text-center text-md-light'>The below section defines the benefits for each tier</p>
                        </div>
                        <div className='col-12'>
                            <div className='position-relative table-wrapper mt-5'>
                                <table className='striped text-center'>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>APY</th>
                                            <th>Additional Tickets</th>
                                            <th>Revenue Share</th>
                                            <th>Voting Rights</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className='table-td d-block d-md-flex align-items-center'>
                                                    <div className='table-image d-flex justify-content-center me-0 me-md-2 mb-1 mb-md-0'><Image src='/img/bronze.png' /></div>
                                                    <div className='table-dec d-flex justify-content-center'>Bronze</div>
                                                </div>
                                            </td>
                                            <td>5%</td>
                                            <td>5%</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='table-td d-block d-md-flex align-items-center'>
                                                    <div className='table-image d-flex justify-content-center me-0 me-md-2 mb-1 mb-md-0 '><Image src='/img/silver.png' /></div>
                                                    <div className='table-dec d-flex justify-content-center'>Silver</div>
                                                </div>
                                            </td>
                                            <td>10%</td>
                                            <td>10%</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td><div className='table-td d-block d-md-flex align-items-center'>
                                                <div className='table-image d-flex justify-content-center me-0 me-md-2 mb-1 mb-md-0'><Image src='/img/gold.png' /></div>
                                                <div className='table-dec'>Gold</div>
                                            </div></td>
                                            <td>15%</td>
                                            <td>15%</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td><div className='table-td d-block d-md-flex align-items-center'>
                                                <div className='table-image d-flex justify-content-center me-0 me-md-2 mb-1 mb-md-0'><Image src='/img/diamond.png' /></div>
                                                <div className='table-dec'>Diamond</div>
                                            </div></td>
                                            <td>20%</td>
                                            <td>20%</td>
                                            <td><Image src={'/img/table.png'} /></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td><div className='table-td d-block d-md-flex align-items-center'>
                                                <div className='table-image d-flex justify-content-center me-0 me-md-2 mb-1 mb-md-0'><Image src='/img/platinum.png' /></div>
                                                <div className='table-dec'>Platinum</div></div></td>
                                            <td>25%</td>
                                            <td>25%</td>
                                            <td><Image src={'/img/table.png'} /></td>
                                            <td><Image src={'/img/table.png'} /></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div style={{ backgroundImage: 'url(/img/tablebg.png)' }} className='shape-imagetable' />
                            </div>
                        </div>
                    </Row>
                </Container>
            </div >
        </>
    );
};


export default Tablesec;
