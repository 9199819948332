import React, { Fragment, useEffect, useRef } from 'react';
import gsap from 'gsap';
import SplitType from 'split-type';
import { faceZoomInUp, fadeInUp, zoomIn } from '../animation/FadeInUp';
import { Col, Image, Row } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { Autoplay, Navigation } from 'swiper/modules';

const NewsSec = () => {
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    return (
        <>
            <Image src={'/img/roadmap-shadow.png'} className='roadmap-shadow' width={'300'} height={'300'} />
            <div className='roadmap-area'>
                <div className='container position-relative'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='section-title-area small-shadow-space shadow-centered text-start d-flex justify-content-between align-items-center position-relative'>
                                <h2 className='gradientText title-anime d-inline-block mb-0'>In the News</h2>
                                <div className='custom-naviagtion'>
                                    <button className='ref-btn prev me-2' ref={prevRef}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19 12H5" stroke="currentcolor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M12 5L5 12L12 19" stroke="currentcolor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </button>
                                    <button className='ref-btn next' ref={nextRef}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5 12H19" stroke="currentcolor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M12 5L19 12L12 19" stroke="currentcolor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row g-md-5'>
                        <div className='col-md-12'>
                            <Swiper
                                slidesPerView={6}
                                spaceBetween={10}
                                className="mySwiper"
                                modules={[Autoplay, Navigation]}
                                autoplay={false}
                                navigation={{
                                    prevEl: prevRef.current,
                                    nextEl: nextRef.current,
                                }}
                                pagination={false}
                                loop={true}
                                onBeforeInit={(swiper) => {
                                    swiper.params.navigation.prevEl = prevRef.current;
                                    swiper.params.navigation.nextEl = nextRef.current;
                                }}
                                breakpoints={{
                                    0: {
                                        slidesPerView: 1,
                                        spaceBetween: 20
                                    },
                                    375: {
                                        slidesPerView: 1,
                                        spaceBetween: 20
                                    },
                                    480: {
                                        slidesPerView: 1,
                                        spaceBetween: 30
                                    },
                                    768: {
                                        slidesPerView: 2,
                                        spaceBetween: 40
                                    },
                                    1024: {
                                        slidesPerView: 3,
                                        spaceBetween: 40
                                    }
                                }}
                            >
                                <SwiperSlide>
                                    <div className='news-card'>
                                        <Image src='img/news/1.png' alt='Cryptonews.com' />
                                        <h3>Cryptonews.com</h3>
                                        <p className='mt-3 mb-0 text-ref text-start text-md-light '>Crypto News is a platform which provides most important news, articles and other content about cryptocurrencies and blockchain today.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='news-card'>
                                        <Image src='img/news/2.png' alt='Cryptonews.com' />
                                        <h3>CoinDesk.com</h3>
                                        <p className='mt-3 mb-0 text-ref2 text-start text-md-light '>Crypto News is a platform which provides most important news, articles and other content about cryptocurrencies and blockchain today.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='news-card'>
                                        <Image src='img/news/3.png' alt='Cryptonews.com' />
                                        <h3>Bloomberg.com</h3>
                                        <p className='mt-3 mb-0 text-ref3 text-start text-md-light '>Crypto News is a platform which provides most important news, articles and other content about cryptocurrencies and blockchain today.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='news-card'>
                                        <Image src='img/news/4.png' alt='Cryptonews.com' />
                                        <h3>crypto.com</h3>
                                        <p className='mt-3 mb-0 text-ref3 text-start text-md-light '>Crypto News is a platform which provides most important news, articles and other content about cryptocurrencies and blockchain today.</p>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NewsSec;
