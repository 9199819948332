import React, { Fragment, useEffect, useRef } from 'react';
import gsap from 'gsap';
import { Container, Image, Row } from 'react-bootstrap';
import TextSplitAnimation from './WordWiseAnimation';
import Accordion from 'react-bootstrap/Accordion';
import SplitType from 'split-type';
import { fadeInUp, zoomIn } from '../animation/FadeInUp';

const FaqSec = () => {

    return (
        <>
            <div className='faq-area'>
                <Container>
                    <Row>
                        <div className='col-md-12'>
                            <div className='section-title-area mb-4 text-center position-relative'>
                                <h2 className='gradientText title-anime'>FAQS</h2>
                                <Image src={'/img/faqsec.png'} width={'100'} height={'100'} />
                            </div>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>HOW CAN I PARTICIPATE IN RAFFLES ON GAMBET</Accordion.Header>
                                    <Accordion.Body>
                                        Users will first have to register by connecting their wallet. They can then participate in any active raffles by buying raffle tickets using USDT.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>HOW DO I KNOW MY ODDs IN RAFFLES I PARTICIPATE</Accordion.Header>
                                    <Accordion.Body>
                                        Our raffles have fixed number of tickets which makes the odds clear. If you buy 1 ticket from a raffle which has 100 tickets, your odds are 1 in 100. If you buy 10 tickets your probability of win increases to 10 out of 100.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>WHEN WILL THE RAFFLES DRAW </Accordion.Header>
                                    <Accordion.Body>
                                        Raffles are drawn same day when the total tickets are sold out. All winners are displayed under each raffle competition.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>WHAT HAPPENS IF THE TOTAL NO OF TICKETS ARE NOT SOLD</Accordion.Header>
                                    <Accordion.Body>
                                        The total value of all tickets sold (excluding platform fees) will be raffled instead of the initial asset.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>HOW CAN I WITHDRAW MY RAFFLE WINS FROM GAMBET</Accordion.Header>
                                    <Accordion.Body>
                                        You can see all your raffle wins in the Claims section. You can connect your wallet and claim the wins directly to your wallet.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>HOW CAN I ACQUIRE OR BUY GAMBET TOKENS</Accordion.Header>
                                    <Accordion.Body>
                                        You can acquire GAMBET tokens through airdrops initially by actively participating on raffle competitions <br />
                                        You will have the opportunity to get GAMBET tokens through launchpads that we will announce. <br />
                                        You will then have the opportunity to buy GAMBET tokens during public sale on GAMBET platform before launch at attractive price.<br />
                                        After launch you will be able to buy GAMBET tokens through the listed DEXs and CEXs we will announce.

                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                    <Accordion.Header>WHAT IS THE BENEFIT OF STAKING GAMBET TOKENS</Accordion.Header>
                                    <Accordion.Body>
                                        Based on the duration and amount of the GAMBET tokens you stake on the platform, you will be awarded a tier level.
                                        This will give you additional benefits when participating in premier raffle competitions.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="7">
                                    <Accordion.Header>HOW CAN WEB3 PROJECTS USE GAMBET PLATFORM TO RUN RAFFLES</Accordion.Header>
                                    <Accordion.Body>
                                        Web3 projects wishing to run marketing campaigns and increase their user adoption can use the GAMBET platform to run raffles for their project token.
                                        The participants will be awarded raffle tickets for buying the project tokens on the GAMBET platform increasing user adoption. The proceeds of this purchase will go the project hosting the raffle.
                                        The project will commit an amount of project tokens to be raffled out to all participants as per the odds and no of winners setup.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </Row>
                </Container>
            </div>
        </>
    );
};


export default FaqSec;
