import gsap from "gsap";

export const fadeInUp = (element, options) => {
    const tl = gsap.timeline();
    tl.fromTo(element,
        { opacity: 0, y: 40 },
        {
            opacity: 1,
            y: 0,
            ease: options.ease || 'easeOutExpo',
            skewY: options.skewY || 0,
            stagger: { amount: options.staggerAmount || 0.4 },
            duration: options.duration || 0.5,
            delay: options.delay || 0,
        }
    );
};

export const zoomIn = (element, options) => {
    const tl = gsap.timeline();
    tl.fromTo(element,
        { opacity: 0, scale: 0.8 },
        {
            opacity: 1,
            scale: 1,
            ease: options.ease || 'easeOutExpo',
            stagger: { amount: options.staggerAmount || 0.4 },
            duration: options.duration || 0.5,
            delay: options.delay || 0,
        }
    );
};

export const faceZoomInUp = (element, options) => {
    const tl = gsap.timeline();
    tl.fromTo(element,
        { opacity: 0, scale: 0.4, y: -40 },
        {
            opacity: 1,
            scale: 1,
            y: 0,
            ease: options.ease || 'easeOutExpo',
            stagger: { amount: options.staggerAmount || 0.4 },
            duration: options.duration || 0.5,
            delay: options.delay || 0.4,
        }
    );
};