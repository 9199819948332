import React, { Fragment, useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import { Container, Row } from 'react-bootstrap';
import TextSplitAnimation from './WordWiseAnimation';
import SplitType from 'split-type';
import { fadeInUp, zoomIn } from '../animation/FadeInUp';

const GambetSec = () => {
    const oddsTextRef = useRef(null);
    const oddsText2Ref = useRef(null);
    const newComminginter = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const split = new SplitType('.odds-content-1,.odds-content-2,.odds-content-3', { types: 'chars,words' });

        const defaults = gsap.timeline();
        defaults.set(".odds-content-1 .char,.odds-content-2 .char,.odds-content-3 .char", { opacity: 0, y: 20, skewY: 10, duration: 0 });

        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0,
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    const targetRef = entry.target;
                    if (targetRef === newComminginter.current) {
                        fadeInUp(".gm-title-anime", { duration: 0.5, delay: 0.5 });
                        fadeInUp(".card-one1", { duration: 0.5, delay: 1 });
                        fadeInUp(".card-two1", { duration: 0.5, delay: 1.5 });
                        fadeInUp(".card-three1", { duration: 0.5, delay: 2 });
                        fadeInUp(".card-four1", { duration: 0.5, delay: 2.5 });
                    }
                    observer.unobserve(targetRef); 

                } else {
                    setIsVisible(false);
                }
            });
        }, observerOptions);

        if (oddsTextRef.current) {
            observer.observe(oddsTextRef.current);
        }
        if (oddsText2Ref.current) {
            observer.observe(oddsText2Ref.current);
        }
        if (newComminginter.current) {
            observer.observe(newComminginter.current);
        }

        return () => {
            if (oddsTextRef.current) {
                observer.unobserve(oddsTextRef.current);
            }
            if (oddsText2Ref.current) {
                observer.unobserve(oddsText2Ref.current);
            }
            if (newComminginter.current) {
                observer.unobserve(newComminginter.current);
            }
        };
    }, []);

    useEffect(() => {
        if (!isVisible) {
            gsap.set(".odds-content-1 .char,.odds-content-2 .char,.odds-content-3 .char", { opacity: 0, y: 20, skewY: 10, duration: 0 });
        }
    }, [isVisible]);

    return (
        <>
            <div className='gambet-area'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='section-title-area shadow-space text-center position-relative'>
                                <h2 className='gradientText gm-title-anime' ref={newComminginter}>Win alluring prizes with gambet</h2>
                                <h3 className='shadow-text'>PRIZES</h3>
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-center gambet-row-wrapper'>
                        <div className='col-lg-10'>
                            <div className='row g-lg-5'>
                                <div className='col-6 col-md-3'>
                                    <div className='gambet-card gambet-top-space card-one1' style={{ opacity: 0 }}>
                                        <h4>Rare NFTs</h4>
                                        <img src='/img/coins/1.png' alt='coin logo' />
                                    </div>
                                </div>
                                <div className='col-6 col-md-3'>
                                    <div className='gambet-card card-two1' style={{ opacity: 0 }}>
                                        <h4>Stablecoins</h4>
                                        <img src='/img/coins/2.png' alt='coin logo' />
                                    </div>
                                </div>
                                <div className='col-6 col-md-3'>
                                    <div className='gambet-card card-three1 gambet-top-space' style={{ opacity: 0 }}>
                                        <h4>bITCOIN &<br/> aLTCOINS</h4>
                                        <img src='/img/coins/3.png' alt='coin logo' />
                                    </div>
                                </div>
                                <div className='col-6 col-md-3'>
                                    <div className='gambet-card card-four1' style={{ opacity: 0 }}>
                                        <h4>Real World Assets (RWA)</h4>
                                        <img src='/img/coins/4.png' alt='coin logo' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </>
    );
};


export default GambetSec;
